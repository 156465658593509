import { render, staticRenderFns } from "./TheTeamsCreationView.vue?vue&type=template&id=bb3b8344&scoped=true&"
import script from "./TheTeamsCreationView.vue?vue&type=script&lang=ts&"
export * from "./TheTeamsCreationView.vue?vue&type=script&lang=ts&"
import style0 from "./TheTeamsCreationView.vue?vue&type=style&index=0&id=bb3b8344&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb3b8344",
  null
  
)

export default component.exports
import {QBtn,QSpinnerDots,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QSpinnerDots,QField})
