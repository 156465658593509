
































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { format } from 'quasar'
import AvatarSelector from '@/components/Modals/Default/_CommonUI/AvatarSelector.vue'
import BaseInput from '@/components/Modals/Default/TwoFactorAuth/components/BaseInput.vue'

import {
  Events,
  DialogEventBus,
} from '@/components/Modals/XModals/DialogEventBus'
import { teamsStore, uiStore, uiSettingsStore } from '@/store'
import { routerLogger } from '@/loggers'

const { capitalize } = format

@Component({
  name: 'TheTeamsCreationView',
  components: {
    BaseInput,
    AvatarSelector,
    ContentHeader: () => import('./components/ContentHeader.vue'),
  },
})
export default class TheTeamsCreationView extends Vue {
  @Ref() readonly TeamNameInput!: BaseInput;

  /**
   * It's also can be EDIT action by passing 'edit' param in route
   */
  isCreateAction = true;

  icon: Blob | null = null;
  isIconChanged = false;

  teamName = '';

  loading = false;

  err = '';

  /**
   * Starting listening event bus for AVATAR changing
   */
  mounted () {
    DialogEventBus.$on(
      Events.OPEN_DIALOG,
      (payload: { instance: string; data: unknown }) => {
        const { instance, data } = payload
        uiStore.actions.showModal({
          instance,
          payload: { data },
        })
      },
    )
  }

  created () {
    const edit = !!this.$route.params.edit

    // in other way it's wrong to edit first team of our teams in store
    if (edit) {
      this.isCreateAction = false
      this.teamName = teamsStore.getters.currentTeam.name
    }
  }

  /**
   * Event bus destroy
   */
  beforeDestroy () {
    DialogEventBus.$off()
  }

  get btnNextLabel (): string {
    return capitalize(this.$t('common.next').toString())
  }

  /**
   * Header of page - Edit or Create team
   */
  get header (): string {
    return this.isCreateAction
      ? this.$t('auth.newOnboarding.teamCreation').toString()
      : this.$t('auth.newOnboarding.teamEditing').toString()
  }

  /**
   * Default placeholder or team avatar if it's editing action
   */
  get avatarUrl (): string {
    return this.isCreateAction
      ? '/assets/NewLogin/user_avatar.svg'
      : teamsStore.getters.teamIconURL(teamsStore.state.currentTeamId ?? '') ??
          ''
  }

  /**
   * Change icon data and flag of changing
   */
  onAvatarUpload ({ file }: { file: Blob }) {
    this.icon = file
    this.isIconChanged = true
    window.goal('onboardingAction', {
      onboarding: 'Создать команду — аватарка установлена',
    })
  }

  /**
   * Push to Teams managment view
   */
  onClickBackArrow () {
    window.goal('onboardingAction', {
      onboarding: 'Создать команду — «Назад»',
    })
    this.$router.push({ name: 'Teams' })
  }

  /**
   * Create/edit team and push to screen AddToTeam for inviting users to new team
   */
  async onSubmit () {
    window.goal('onboardingAction', {
      onboarding: 'Создать команду — «Продолжить»',
    })
    const name = this.teamName.replace(/\s/g, '')
    if (!name.length) {
      this.err = this.$t('auth.newOnboarding.noTeamName').toString()
      this.TeamNameInput.focus()
      return
    }

    let team = null
    this.loading = true

    try {
      if (this.isCreateAction) {
        team = await teamsStore.actions.createTeam({
          name: this.teamName.trim(),
          avatar: this.icon,
        })
      } else {
        team = await teamsStore.actions.editTeam({
          uid: teamsStore.state.currentTeamId ?? '',
          name: this.teamName.trim(),
          avatar: this.icon,
        })
      }
    } catch (e) {
      this.err =
        (e?.details?.name || e?.details?.file || e?.details?.['']) ?? e.error
      this.loading = false
      this.TeamNameInput.focus()
      return
    }

    window.goal('onboardingAction', {
      onboarding: 'Управление командами — «Создать команду»',
    })

    teamsStore.actions.updateTeam(team)
    teamsStore.actions.initTeam(team.uid)
    uiSettingsStore.actions.initTeam({ teamId: team.uid })

    this.$router.push({ name: 'AddToTeam' }).catch(e => {
      routerLogger.debug('Router warning UserProfile: ', e)
    })
  }

  /**
   * Send metric goal for each avatar change event
   */
  onAvatarSelectorClick () {
    window.goal('onboardingAction', {
      onboarding: 'Создать команду — клик по пикеру аватарки',
    })
  }
}
